
import { CouponApi } from '@/api/coupons';
import { useMainStore } from '@/store/modules/main';
import { usePlanningStore } from '@/store/modules/planning';
import { GetCouponNameResponse } from '@/types/response/coupon';
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: "PaymentCoupon"
})
export default class PaymentCoupon extends Vue {
    public isCoupon = false;
    public planningStore = usePlanningStore();
    public mainStore = useMainStore();
    public couponName = "";
    public loadingCoupon = false
    public couponResponse: GetCouponNameResponse | null = null;
    private couponApi = new CouponApi();
    public discount = 0;


    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected;
    }

    get passengers() {
        return this.planningStore.passengers;
    }

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get total() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            const acum = this.tripsSelecteds.reduce((a, b) => a + b.price, 0)
            return acum * (1 + this.passengers.length)
        }
        let price = this.tripSelected?.price || 0;
        if (this.searchTripBooking?.typeTrip === "round") {
            price += this.tripFinalSelected?.price || 0;
        }
        return price * (1 + this.passengers.length);
    }

    async searchCoupon() {
        if (this.couponName === "") return;
        this.loadingCoupon = true;
        try {
            const coupon = await this.couponApi.validateCoupon(
                this.couponName,
                this.total
            );
            this.discount =
                coupon.type === "PERCENTAJE" || coupon.percentaje
                    ? this.calculatePercenjate(coupon)
                    : coupon.amount || 0;
            this.couponResponse = coupon;
            this.mainStore.setNotification({
                isOpen: true,
                message: "Cupón agregado correctamente",
                color: "green darken-2",
                buttonClose: true,
            });
            localStorage.setItem("coupon", JSON.stringify(coupon));
        } catch (error) {
            getErrorCatch(error);
            this.couponResponse = null;
            this.discount = 0;
        }
        this.$emit('searchCoupon', {
            couponResponse: this.couponResponse,
            discount: this.discount,
            name: this.couponName
        });
        this.loadingCoupon = false;
    }
    calculatePercenjate(coupon?: GetCouponNameResponse) {
        if (coupon) {
            if (
                (coupon.type === "PERCENTAJE" || coupon.percentaje) &&
                coupon.percentaje
            ) {
                const discount = this.total * (coupon.percentaje / 100);
                return isNaN(discount) ? 0 : discount;
            }
        }
        return 0;
    }

    updateCoupon(value: boolean) {
        if (!value) {
            this.couponName = '';
            this.$emit('searchCoupon', {
                couponResponse: null,
                discount: 0,
                name: ''
            })
        }
    }
}
