
import { formatDateNoonTimestamp, getDateStarDay } from "@/filters/formatDate";
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";
import { FormVuetify, SelectData } from "@/types/common/vuetify";
import { GetBookingRequest } from "@/types/request/planning";
import { getDaysInRange, getErrorCatch, requiredField, sleep } from "@/utils";
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import BannerMobile from "@/assets/images/banners/banner_mov.png";
import { useDisplay } from "vuetify";
import { customScrollTo } from "@/utils";
import { useAuthStore } from "@/store/modules/auth";
import { useMainStore } from "@/store/modules/main";

@Options({
  name: "FormSearchBooking",
  components: {
    HeaderCommonBooking,
  },
  props: {
    home: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class FormSearchBooking extends Vue {
  public dateSelected = new Date();
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  private mainStore = useMainStore();
  private authStore = useAuthStore();
  public requiredField = requiredField;
  public searchLoading = false;
  public getDateStarDay = getDateStarDay;
  public valueTempSW = false
  public mobile = false;
  public home!: boolean;
  public fromSend = false;
  public listTypeTrip: SelectData[] = [
    {
      title: "Ida y vuelta",
      value: "round",
    },
    {
      title: "Solo ida",
      value: "justReturn",
    },
  ];

  public banner = BannerMobile;
  public timeOut = 0;

  public dateCreateTrip: GetBookingRequest = {
    origin: undefined,
    destination: undefined,
    date: new Date(),
    typeTrip: "round",
    dateFinal: new Date(),
  };

  get buyMassive() {
    return this.planningStore.buyMassive
  }

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }

  get minDateFinal() {
    if (this.buyMassive) {
      const dates = this.dateCreateTrip.date as Date[];
      if (!dates) {
        return new Date()
      }
      if (dates.length == 0) {
        return new Date();
      }
      return new Date(dates[0]);
    } else {
      return new Date(this.dateCreateTrip.date as number)
    }
  }

  get isErrorDatesRange() {
    if (this.fromSend) {
      if (this.buyMassive) {
        const dates = this.dateCreateTrip.date as Date[];
        return dates.length == 0
      }
      return false;
    }
    return false;
  }

  get isErrorDatesRangeFinal() {
    if (this.fromSend) {
      if (this.buyMassive) {
        const dates = this.dateCreateTrip.dateFinal as Date[];
        return dates.length == 0
      }
      return false;
    }
    return false;
  }

  get disableDateFinal() {
    if (this.buyMassive) {
      const dates = this.dateCreateTrip.date as Date[];
      if (!dates) {
        return true
      }
      return dates.length == 0;
    } else {
      return !this.dateCreateTrip.date
    }
  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get isSigned() {
    return this.authStore.user?.isSign;
  }

  get stations() {
    return this.routeStore.stationsList;
  }
  get tripsSearch() {
    return this.planningStore.tripsSelected;
  }

  get searchSelected() {
    return this.planningStore.searchSelected;
  }

  get checkMainPage() {
    if (!this.isMobile) {
      return true;
    }
    const router = this.$route.path;
    if (router === "/booking") {
      return this.tripsSearch.length > 0;
    }
    return false;
  }

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
    const route = this.$route.path;
    try {
      if (route === "/booking") {
        const dataLocal = localStorage.getItem("searchSelected");
        const isMassiveStr = localStorage.getItem('isMassive');
        this.planningStore.setBuyMultiple(isMassiveStr ? isMassiveStr == 'true' : false)
        if (dataLocal) {
          const searchSelected = JSON.parse(dataLocal) as GetBookingRequest;
          if (this.buyMassive) {
            this.valueTempSW = true
            this.dateCreateTrip.date = (searchSelected.date as string[]).map((day) => new Date(day));
            const dayOne = new Date(this.dateCreateTrip.date[0]).toISOString().split('T')[0].split("-")[2]
            this.planningStore.setDaySelectedMassive(dayOne)
          } else {
            const dateSearch = new Date(searchSelected.date as number);
            this.dateCreateTrip.date = dateSearch;
          }

          this.dateCreateTrip.destination = searchSelected.destination;
          this.dateCreateTrip.origin = searchSelected.origin;
          this.dateCreateTrip.typeTrip = searchSelected.typeTrip;
          this.dateCreateTrip.dateFinal = searchSelected.dateFinal;
          this.search()
        }
      }
    } catch (error) {
      getErrorCatch(error);
    }
  }

  setNoonDate(dateEntry: number | Date) {
    const dateFormat = new Date(dateEntry);
    dateFormat.setHours(12);
    dateFormat.setMinutes(0);
    dateFormat.setSeconds(0);
    return dateFormat;
  }

  async search() {
    const form = this.$refs["formSearch"] as FormVuetify;
    if (!form) return;
    const valid = await form.validate();
    this.fromSend = true;
    if (valid.valid) {
      if (!this.authStore.isLogged) {
        this.mainStore.setNotification({
          isOpen: true,
          message:
            "Para continuar con la compra, debes iniciar sesión o crear tu cuenta.",
          isLink: true,
          link: "/login",
          nameLink: "Aquí",
          color: "red-darken-2",
          timeout: 3000,
          buttonClose: false,
        });
        return;
      } else {
        if (!this.isSigned) {
          this.mainStore.setNotification({
            isOpen: true,
            message:
              "Para continuar con la compra, debes firmar tu contrato.",
            isLink: true,
            link: "/perfil",
            nameLink: "Aquí",
            color: "red-darken-2",
            timeout: 3000,
            buttonClose: false,
          });
          return;
        }
      }
      if (this.isErrorDatesRange) {
        console.log("fechas de entrada vacia")
        return;
      }
      if (this.dateCreateTrip.typeTrip == 'round' && this.isErrorDatesRangeFinal) {
        console.log("fechas de entrada vacia")
        return;
      }
      let dateInit: number | number[] = 0;
      let dateFinish: number | undefined = undefined;
      if (this.buyMassive) {
        dateInit = (this.dateCreateTrip.date as Date[]).map((date) => formatDateNoonTimestamp(date.getTime()).toMillis());
      } else {
        dateInit = formatDateNoonTimestamp(new Date(this.dateCreateTrip.date as number).getTime()).toMillis();
      }

      if (this.dateCreateTrip.typeTrip == 'round') {
        dateFinish = formatDateNoonTimestamp(new Date(this.dateCreateTrip.dateFinal as number).getTime()).toMillis();
      }

      this.searchLoading = true;
      this.planningStore.setLoadingBooking(true);
      try {
        await Promise.all([
          this.planningStore.getTripsOutbound(this.dateCreateTrip,dateInit),
          dateFinish ? this.planningStore.getTripsReturn(this.dateCreateTrip,dateFinish) : null
        ])
        this.planningStore.resetSearchTrip();
        this.planningStore.updateStageTrip("outbound");
        // seating default
        if(this.buyMassive){
          console.log(dateInit)
          const dates = dateInit as number[]
          const range = getDaysInRange(dates)
          console.log(range)
          let daySelected : string | undefined = undefined;
          for (let index = 0; index < range.length; index++) {
            const element = range[index];
            const exist = this.tripsSearch.filter((ts) => ts.dateExecuting.split("T")[0].split("-")[2] == element.day)
            if(exist.length > 0){
              daySelected = element.day
              break;
            }
          }
          if(!daySelected){
            throw new Error("No existe planificaciones en el rango seleccionado")
          }else{
            this.planningStore.setDaySelectedMassive(daySelected)
            this.planningStore.setDaySelectedSeating(daySelected)
          }
          this.planningStore.setSelectedAllDays(false);
          this.planningStore.setRangeDatesMassive(dates);
        }
      } catch (error) {
        getErrorCatch(error)
      }
      this.planningStore.setLoadingBooking(false);
      this.searchLoading = false;
      if (this.mobile) {
        await sleep(300, this.timeOut);
        customScrollTo("listTrips", 0);
      }
      if (this.home) {
        this.$router.push("/booking");
      }
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  different(value: string, valueCompare?: string) {
    if (!valueCompare) return true;
    return value !== valueCompare ? true : "Campo igual a origen";
  }
  back() {
    if (this.mobile) {
      this.planningStore.clearBooking();
      this.dateCreateTrip = {
        date: new Date(),
        destination: undefined,
        origin: undefined,
        dateFinal: new Date(),
        typeTrip: "round",
      };
      return;
    }
    this.$router.push("/");
  }
  setTypeTrip(value: string) {
    if (value === "round") {
      if (this.buyMassive) {
        const dates = this.dateCreateTrip.date as Date[];
        if (dates.length > 0) {
          this.dateCreateTrip.date = new Date(dates[0]);
          this.dateCreateTrip.dateFinal = new Date(dates[0]);
        }
      } else {
        this.dateCreateTrip.dateFinal = new Date();
        this.dateCreateTrip.date = new Date();
      }
      this.planningStore.setBuyMultiple(false);
    } else {
      this.dateCreateTrip.dateFinal = undefined;
      if (!this.buyMassive) {
        this.dateCreateTrip.date = new Date()
      } else {
        this.dateCreateTrip.date = []
      }
    }
  }
  updateDateFinal(value: Date) {
    if (this.dateCreateTrip.typeTrip === "round") {
      this.dateCreateTrip.dateFinal = new Date(value);
    }
  }
  setMassive(value: boolean) {
    this.planningStore.setBuyMultiple(value);
    if (value) {
      this.dateCreateTrip.date = []
      if (this.dateCreateTrip.typeTrip == 'round') {
        this.dateCreateTrip.dateFinal = [];
      }
    } else {
      this.dateCreateTrip.date = new Date()
      this.dateCreateTrip.dateFinal = new Date()
    }
  }
}
