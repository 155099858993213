
import { Vue, Options } from 'vue-class-component';
import { useMainStore } from '@/store/modules/main';
import Plane from '@/assets/images/plane.png'
import BackgroundPlane from '@/assets/images/background.jpg'

@Options({
  name: 'App',
})
export default class App extends Vue {
  store = useMainStore();
  public modalAirport = false;
  public Plane = Plane
  public BackgroundPlane = BackgroundPlane
  mounted() {
    this.store.getMenu()
    this.loadModalAirport()
  }

  loadModalAirport(){
    const isSeeModal = localStorage.getItem('modalAirport');
    if(!isSeeModal){
      this.modalAirport = true
    }
  }
  closeModalAirport(){
    this.modalAirport = false;
    localStorage.setItem('modalAirport',JSON.stringify({value : true}))
  }
}
