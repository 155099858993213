
import { getDate } from '@/filters/formatDate';
import { usePlanningStore } from '@/store/modules/planning';
import { useRoutesStore } from '@/store/modules/route';
import { Options, Vue } from 'vue-class-component';


@Options({
    name: "DescriptionRound",
    components: {
    }
})
export default class DescriptionRound extends Vue {
    public planningStore = usePlanningStore();
    private routeStore = useRoutesStore();
    public getDate = getDate;

    get stageTrip() {
        return this.planningStore.stageTrip;
    }

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    getRouteById(id: string) {
        return this.routeStore.getStationById(id)?.name || "-";
    }

}
