
import { Vue, Options } from "vue-class-component";
import LocationPayment from "@/assets/images/icons/location-payment.svg";
import { convertTo12HourFormat } from "@/filters/formatDate";
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";

@Options({
  name: "Seating",
  props: {
    title: {
      type: String,
      default: "",
    },
    valueTitle: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    valueSubTitle: {
      type: String,
      default: "",
    },
  },
})
export default class PaymentBoxRoute extends Vue {
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  public LocationPayment = LocationPayment;
  public convertTo12HourFormat = convertTo12HourFormat;
  public title !:string;
  public valueTitle !:string;
  public subTitle !:string;
  public valueSubTitle !:string;

  get stations() {
    return this.routeStore.stationsList;
  }

  get isMassive(){
    return this.planningStore.buyMassive
  }

  getStationSelected(id?: string) {
    return this.stations.find(({ _id }) => _id === id)?.name || "";
  }
}
