
import { Options, Vue } from 'vue-class-component';
import { usePlanningStore } from '@/store/modules/planning';
import seatingCount from '@/assets/images/seatings/seatingCount.svg'
import { dateToDayMonth } from '@/utils';


@Options({
    name: 'InfoBusSeatings',
    props: {
        seatingSelected: {
            type: Array,
            default: () => ([]),
            required: false,
        },
    },
})
export default class InfoBusSeatings extends Vue {
    public planningStore = usePlanningStore();
    public seatingCount = seatingCount;
    public seatingSelected !: string[]

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get stageSeating() {
        return this.planningStore.stageSeatings
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get statusNextStep() {
        if (this.isMassive) {
            if (this.planningStore.selectedAllSeating && this.seatingSelected.length > 0) {
                return false
            }
            const tripsSeatings = this.tripsSelecteds.filter(({ seatingSelected }) => {
                if (!seatingSelected || seatingSelected.length == 0) {
                    return false
                }
                return true
            }).map(({ seatingSelected }) => seatingSelected?.length)
            if (tripsSeatings.length !== this.tripsSelecteds.length) {
                return true;
            }
            return tripsSeatings.some((value) => value !== tripsSeatings[0])
        }
        return this.seatingSelected.length === 0
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get passengers() {
        let pass: string[] = []
        this.planningStore.owner && pass.push(this.planningStore.owner.seating);
        pass = [...pass, ...this.planningStore.passengers.map(({ seating }) => seating)].sort(this.sortSeatings)
        return pass
    }

    get selectedAllSeating(){
        return this.planningStore.selectedAllSeating
    }

    get seatingsMassive() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            const seatings =  this.tripsSelecteds.map(({ dateExecuting, seatingSelected }) => {
                const day = dateToDayMonth(new Date(dateExecuting).getTime())
                if(this.selectedAllSeating){
                    return `${day.day} ${day.month}`
                }
                const existsSeatings = seatingSelected && seatingSelected.length > 0 ? seatingSelected.join(", ") : 'Sin asientos'
                return `${day.day} ${day.month} <span class="text-caption">(${existsSeatings})</span>`
            }).join(', ')
            if(this.selectedAllSeating){
                return `${seatings} <span class="text-caption ml-1">(${this.tripsSelecteds[0].seatingSelected || 'Sin asientos'})</span>`
            }
            return seatings
        }
        return ''
    }

    sortSeatings(a: string, b: string) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }

    backStepSeating() {
        this.$emit('back')
    }

    nextStep() {
        this.$emit('nextStep')
    }

}
