
import { Vue, Options } from "vue-class-component";
import { usePlanningStore } from "@/store/modules/planning";
import { GetBookingResponse } from "@/types/response/planning";
import { useMainStore } from "@/store/modules/main";
import { useDisplay } from "vuetify";
import { useAuthStore } from "@/store/modules/auth";
import { convertTo12HourFormat } from "@/filters/formatDate";
import { customScrollTo, formatCurrency, getErrorCatch, sleep } from "@/utils";
import { useRoutesStore } from "@/store/modules/route";
import { PlanningTripAPI } from "@/api/planning";

@Options({
    name: "ItemListTrip",
})
export default class ItemListTrip extends Vue {
    public planningStore = usePlanningStore();
    private routeStore = useRoutesStore();
    private planningTripApi = new PlanningTripAPI();
    public mobile = false;
    private mainStore = useMainStore();
    public convertTo12HourFormat = convertTo12HourFormat;
    private authStore = useAuthStore();
    public formatCurrency = formatCurrency;
    public isLoadingNotification = false;
    public timeOut = 0;
    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    get stageTrip() {
        return this.planningStore.stageTrip;
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get tripsSearch() {
        return this.planningStore.tripsSelected;
    }

    get tripsFinalSearch() {
        return this.planningStore.tripsSelectedFinal;
    }

    get listTrips() {
        if (!this.searchSelected) {
            return []
        }
        if (this.searchSelected.typeTrip === 'round') {
            if (this.stageTrip === 'outbound') {
                return this.tripsSearch
            }
            return this.tripsFinalSearch
        }
        return this.tripsSearch
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get dayselection() {
        return this.planningStore.daySelectedMassive
    }

    get listTripsMassive() : GetBookingResponse[] {
        if (this.isMassive) {
            return this.tripsSearch.filter(({ dateExecuting }) => {
                const dayExecution = dateExecuting.split('T')[0].split('-')[2]
                return dayExecution == this.dayselection
            }).map((trip) => {
                const selected = this.tripsSelecteds.find(({ _id, dateExecuting }) => {
                    const date = dateExecuting.split("T")[0].split("-")[2]
                    const dateSelected = this.dayselection
                    return _id == trip._id && date == dateSelected
                })
                return {
                    ...trip,
                    isSelected: !!selected,
                    code : selected ? selected.code : null,
                    isEmpty: trip.vehicle.seatings - trip.seatings.length === 0,
                }
            })
        }
        return this.listTrips.map((trip) => {
            return {
                ...trip,
                isSelected: null,
                isEmpty: trip.vehicle.seatings - trip.seatings.length === 0
            }
        })
    }

    get stations() {
        return this.routeStore.stationsList;
    }

    get allDaysSelected() {
        return this.planningStore.selectedAllDays
    }

    get rangeDatesMassive(){
        return this.planningStore.rangeDatesMassive
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || "";
    }


    mounted(): void {
        const { mobile } = useDisplay();
        this.mobile = mobile.value;
    }

    getTripsActiveDay(day: string) {
        return this.tripsSearch.filter(({ dateExecuting }) => {
            return dateExecuting.split('T')[0].split('-')[2] == day
        }).length > 0
    }

    async addTrip(trip: GetBookingResponse) {
        if (trip.vehicle.seatings - trip.seatings.length === 0) {
            return;
        }
        if (this.isMassive && this.searchSelected) {
            if (this.allDaysSelected) {
                // todos los dias seleccionados
                const trips = this.rangeDatesMassive.filter(({ disabled }) => !disabled).map((day) => {
                    const trips = this.tripsSearch.filter(({ dateExecuting, hourInit }) => {
                        return dateExecuting.split('T')[0].split('-')[2] == day.day && hourInit == trip.hourInit
                    })
                    return {
                        ...day,
                        trips
                    }
                })
                if (trips.some(({ trips }) => trips.length == 0)) {
                    this.mainStore.setNotification({
                        isOpen: true,
                        color: 'red-darken-2',
                        message: "Tu selección no existen en los demás dias, selecciona otro horario",
                        timeout: 2000
                    })
                    return;
                }
                for (let index = 0; index < trips.length; index++) {
                    const element = trips[index];
                    for (let index = 0; index < element.trips.length; index++) {
                        const elementTrip = element.trips[index];
                        // agregando cada viaje
                        this.planningStore.addTripSelecteds(elementTrip);
                    }
                }
                customScrollTo("null", 0);
            } else {
                // dia por dia
                this.planningStore.addTripSelecteds(trip);
                this.mainStore.setNotification({
                    isOpen: true,
                    color: 'green-darken-2',
                    timeout : 1500,
                    message: 'Dia seleccionado, puede continuar con el siguiente.'
                })
            }
            return;
        }
        this.planningStore.setTrip(trip);
        if (this.searchSelected?.typeTrip === "round") {
            if (this.stageTrip == "outbound") {
                this.mainStore.setNotification({
                    isOpen: true,
                    message: "Seleccione su viaje de vuelta.",
                    color: "green darken-2",
                });
                this.planningStore.updateStageTrip("return");
            }
        }
        if (this.mobile) {
            await sleep(300, this.timeOut);
            if (this.searchSelected?.typeTrip == "round") {
                if (this.stageTrip == "return") {
                    customScrollTo("type-trip-title", 0);
                    return;
                }
                customScrollTo("null", 0);
            }
            customScrollTo("null", 0);
        }
    }

    existActiveTripNotification(trip: GetBookingResponse) {
        const user = this.authStore.user;
        if (user) {
            const notifications = user.activeTripNotifications;
            return (
                notifications.find((not) => not.includes(trip.activeTrip)) == undefined
            );
        }
        return false;
    }

    async addNotificationUser(
        trip: GetBookingResponse,
        type: "add" | "remove" = "add"
    ) {
        this.isLoadingNotification = true;
        try {
            if (type == "add") {
                const routeFrom = this.getStationSelected(this.searchSelected?.origin || "");
                const routeTo = this.getStationSelected(this.searchSelected?.destination || "");
                await this.planningTripApi.addUserNotification(trip.activeTrip, {
                    hourFinish: trip.hourFinish,
                    hourInit: trip.hourInit,
                    routeFrom: this.stageTrip == 'return' ? routeTo : routeFrom,
                    routeTo: this.stageTrip == 'return' ? routeFrom : routeTo
                });
            } else {
                await this.planningTripApi.removeUserNotification(trip.activeTrip);
            }
            await this.authStore.getUser();
        } catch (error) {
            getErrorCatch(error);
        }
        this.isLoadingNotification = false;
    }

}
