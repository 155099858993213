
import { usePlanningStore } from "@/store/modules/planning";
import { SelectData } from "@/types/common/vuetify";
import { Vue, Options } from "vue-class-component";
import { useDisplay } from "vuetify";
import ItemListTrip from "./ItemListTrip.vue";
import DescriptionRound from "./DescriptionRound.vue";
import DescriptionMassive from "./DescriptionMassive.vue";
@Options({
  name: "ListTripResult",
  components: {
    ItemListTrip,
    DescriptionRound,
    DescriptionMassive
  }
})
export default class ListTripResult extends Vue {
  public planningStore = usePlanningStore();
  public mobile = false;
  public timeOut = 0;
  public isLoadingNotification = false;
  public items: SelectData[] = [
    {
      title: "Menor precio",
      value: "price-low",
    },
    {
      title: "Salida más temprano",
      value: "earlier",
    },
    {
      title: "Salida más tarde",
      value: "late",
    },
  ];

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  get tripsSearch() {
    return this.planningStore.tripsSelected;
  }

  get tripsFinalSearch() {
    return this.planningStore.tripsSelectedFinal;
  }

  get emptyTrips() {
    return this.planningStore.emptyTrips;
  }

  get isLoadingBooking() {
    return this.planningStore.isLoadingBooking;
  }

  get searchSelected() {
    return this.planningStore.searchSelected;
  }

  backTripFirst() {
    this.planningStore.updateStageTrip("outbound");
  }

  setType(value: string) {
    switch (value) {
      case "price-low":
        this.planningStore.orderPrice();
        break;
      case "earlier":
        this.planningStore.orderTime();
        break;
      case "late":
        this.planningStore.orderTime(false);
        break;
      default:
        break;
    }
  }
}
