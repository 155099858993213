import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import {
  AddNotificationActiveTripReq,
  CreatedPlanningTrip,
  CreatedPlanningTripStation,
  GetBookingRequest,
  GetPlanningTrips,
} from "@/types/request/planning";
import { PLANNING_TRIP_ENDPOINTS } from "./endpoints";
import {
  DeletedFailTrips,
  GetBookingResponse,
  GetCalendarResponse,
  PlanningTripsResponse,
  PlanningTripsResponsePagination,
  PlanningTripsStationsResponse,
  STATUS_ACTIVE_TRIP,
  SelectPlanning,
  TripTodayDriverResponse,
} from "@/types/response/planning";

export class PlanningTripAPI {
  private request = new API(ROUTES_GLOBAL.PLANNING_TRIP);
  private api: AxiosInstance = this.request.getInstance();

  async createPlanningTrip(dataCreated: CreatedPlanningTrip) {
    const { data } = await this.api.post<string>(
      PLANNING_TRIP_ENDPOINTS.CREATE_PLANNING_TRIP,
      dataCreated
    );
    return data;
  }

  async createDataStationsPlanningTrip(data: CreatedPlanningTripStation) {
    await this.api.post(
      PLANNING_TRIP_ENDPOINTS.CREATE_PLANNING_TRIP_STATION,
      data
    );
  }

  async getPlanningTrips({ limit, page }: GetPlanningTrips) {
    const URL = `${PLANNING_TRIP_ENDPOINTS.GET_ALL_PLANNING_TRIPS}?page=${page}&limit=${limit}`;
    const { data } = await this.api.get<PlanningTripsResponsePagination>(URL);
    return data;
  }

  async getStationsPlanningTrip(id: string) {
    const URL = `${PLANNING_TRIP_ENDPOINTS.GET_TRIP_STATIONS_PLANNING}${id}`;
    const { data } = await this.api.get<PlanningTripsStationsResponse[]>(URL);
    return data;
  }

  async getBooking({
    date,
    destination,
    origin
  }: GetBookingRequest) {
    let URL: string = PLANNING_TRIP_ENDPOINTS.GET_BOOKING;
    if (date && destination && origin) {
      URL += `?destination=${destination}&origin=${origin}&date=${date}`;
    }
    const { data } = await this.api.get<GetBookingResponse[]>(URL);
    return data;
  }

  async getCalendar(id: string,date?: number) {
    const { data } = await this.api.get<GetCalendarResponse[]>(
      `${PLANNING_TRIP_ENDPOINTS.GET_CALENDAR_TRIP}${id}?date=${date}`
    );
    return data;
  }

  async getPlanningDriver() {
    const { data } = await this.api.get<PlanningTripsResponse[]>(
      `${PLANNING_TRIP_ENDPOINTS.GET_ALL_PLANNING_TRIPS}`
    );
    return data;
  }

  async updateSeatingStatus(id: string, status: string) {
    await this.api.put(
      `${PLANNING_TRIP_ENDPOINTS.UPDATE_STATUS_SEATING}${id}`,
      { status }
    );
  }

  async getTripsToday() {
    const { data } = await this.api.get<TripTodayDriverResponse[]>(
      PLANNING_TRIP_ENDPOINTS.GET_TRIPS_TODAY_DRIVER
    );
    return data;
  }

  async suspendSeating(id: string) {
    await this.api.put(PLANNING_TRIP_ENDPOINTS.SUSPEND_SEATING + id);
  }

  async updateDriverPlanning(driver: string, planning: string) {
    await this.api.put(
      PLANNING_TRIP_ENDPOINTS.UPDATE_DRIVER_PLANNING + planning + "/" + driver
    );
  }

  async updateVehiclePlanning(driver: string, planning: string) {
    await this.api.put(
      PLANNING_TRIP_ENDPOINTS.UPDATE_VEHICLE_PLANNING + planning + "/" + driver
    );
  }

  async updateStatus(id: string, status: STATUS_ACTIVE_TRIP) {
    await this.api.put(PLANNING_TRIP_ENDPOINTS.UPDATE_STATUS_TRIP + id, {
      status,
    });
  }

  async updateHourInitStation(
    id: string,
    hour: string,
    typeUpdate: "INIT" | "FINISH" = "INIT"
  ) {
    await this.api.patch(
      PLANNING_TRIP_ENDPOINTS.UPDATE_HOUR_STATION_PLANNING + id,
      { hour, typeUpdate }
    );
  }

  async updateDurationStation(id: string, durationReal: number) {
    await this.api.patch(
      PLANNING_TRIP_ENDPOINTS.UPDATE_DURATION_STATION_PLANNING + id,
      { durationReal }
    );
  }

  async updateHourStation(id: string, price: number) {
    await this.api.patch(
      PLANNING_TRIP_ENDPOINTS.UPDATE_PRICE_STATION_PLANNING + id,
      { price }
    );
  }

  async removeActiveTrip(id: string) {
    await this.api.delete(PLANNING_TRIP_ENDPOINTS.REMOVE_ACTIVE_TRIP + id);
  }

  async addRemoveActiveTrip(id: string) {
    await this.api.put(PLANNING_TRIP_ENDPOINTS.ADD_ACTIVE_TRIP + id);
  }

  async getPDF(id: string) {
    const data = (await this.api.get(PLANNING_TRIP_ENDPOINTS.GET_PDF + id, {
      responseType: "blob",
    })) as Blob;
    return data;
  }

  async getSelectPlanning() {
    const { data } = await this.api.get<SelectPlanning[]>(
      PLANNING_TRIP_ENDPOINTS.GET_ALL_PLANNING_TRIPS_LIST
    );
    return data;
  }

  async updateNamePlanning(id: string, name: string) {
    await this.api.patch(PLANNING_TRIP_ENDPOINTS.UPDATE_NAME_PLANNING + id, {
      name,
    });
  }

  async addDays(id: string, days: Date[], isRange: boolean) {
    await this.api.patch(PLANNING_TRIP_ENDPOINTS.ADD_DAYS + id, {
      days,
      isRange,
    });
  }

  async getStationsPlanning(id: string) {
    const { data } = await this.api.get<PlanningTripsStationsResponse[]>(
      PLANNING_TRIP_ENDPOINTS.GET_STATIONS_PLANNING + id
    );
    return data;
  }

  async deletePlanning(id: string) {
    const { data } = await this.api.delete<DeletedFailTrips>(
      PLANNING_TRIP_ENDPOINTS.DELETE_PLANNING + id
    );
    return data;
  }

  async generateReport(id: string, dateInit: number, dateFinish: number) {
    const data = await this.api.get<string>(
      PLANNING_TRIP_ENDPOINTS.GENERATED_REPORT +
        id +
        `?dateInit=${dateInit}&dateFinish=${dateFinish}`
    );
    return data;
  }

  async addUserNotification(activeTrip: string,data : AddNotificationActiveTripReq){
    await this.api.post(PLANNING_TRIP_ENDPOINTS.ADD_USER_NOTIFICATION+activeTrip,data)
  }

  async removeUserNotification(activeTrip: string){
    await this.api.delete(PLANNING_TRIP_ENDPOINTS.REMOVE_USER_NOTIFICATION+activeTrip)
  }
}
