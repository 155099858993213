
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import { usePlanningStore } from "@/store/modules/planning";
import { CustomerSeating } from "@/types/request/planning";
import {
  formatRutChile,
  validateEmail,
  requiredField,
  validateNumber,
  ruleValidateRut,
  maxItem,
  repeatEmail,
  getErrorCatch,
  dateToDayMonth,
} from "@/utils";
import { FormVuetify } from "@/types/common/vuetify";
import { useDisplay } from "vuetify";
import { customScrollTo, sleep } from "@/utils";
import { UserApi } from "@/api/users";
import { useMainStore } from "@/store/modules/main";
import { useAuthStore } from "@/store/modules/auth";
import ModalCommon from "../common/ModalCommon.vue";
import { Header } from "vue3-easy-data-table";
@Options({
  name: "Passengers",
  components: {
    HeaderCommonBooking,
    ModalCommon,
  },
})
export default class Passengers extends Vue {
  public planningStore = usePlanningStore();
  private userApi = new UserApi();
  private authStore = useAuthStore();
  validateEmail = validateEmail;
  requiredField = requiredField;
  ruleValidateRut = ruleValidateRut;
  maxItem = maxItem;
  repeatEmail = repeatEmail;
  public repeatEmailValue = "";
  public blockedSeating: string[] = [];
  public dataOwner: CustomerSeating = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "+569",
    rut: "",
    seating: "",
  };
  public mobile = false;
  public timeOut = 0;
  public loaderUserRUT = false;
  public isExistUser = false;
  public isSignUser = false;
  public loadStep = false;
  public isVisibleModalFavorite = false;
  public seatingFavoriteType: "owner" | "seating" | null = null;
  public seatingFavoriteSelected: null | number = null;
  public passengerselected = "";

  public headers: Header[] = [
    { text: "Nombres", value: "firstName" },
    { text: "Apellidos", value: "lastName" },
    { text: "Correo", value: "email" },
    { text: "RUT", value: "rut" },
    { text: "Celular", value: "phone" },
    { text: "", value: "actions" },
  ];

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
  }

  get isMassive() {
    return this.planningStore.buyMassive
  }

  get tripsSelecteds() {
    return this.planningStore.tripsSelecteds
  }

  get selectedAllSeating() {
    return this.planningStore.selectedAllSeating
  }

  get ownerSeating() {
    if (this.isMassive  && this.tripsSelecteds.length > 0) {
      const seatingsEqual = this.tripsSelecteds.map(({ seatingSelected }) => seatingSelected !== undefined && seatingSelected.length > 0 ? seatingSelected[0] : undefined)
      console.log(seatingsEqual)
      if (!seatingsEqual.some((value) => value !== seatingsEqual[0])) {
        const days = this.tripsSelecteds.map(({ dateExecuting }) => {
          const day = dateToDayMonth(new Date(dateExecuting).getTime())
          return `${day.day} ${day.month}`
        }).join(', ')
        return seatingsEqual[0] + `<span class="text-caption ml-1">(${days})</span>`
      }
      const seatings = this.tripsSelecteds.map(({ dateExecuting, seatingSelected }) => {
        const day = dateToDayMonth(new Date(dateExecuting).getTime())
        if (this.selectedAllSeating) {
          return `${day.day} ${day.month}`
        }
        const existsSeatings = seatingSelected && seatingSelected.length > 0 ? seatingSelected[0] : 'Sin asientos'
        return `${day.day} ${day.month} <span class="text-caption">(${existsSeatings})</span>`
      }).join(', ')
      return seatings
    }
    return this.owner?.seating || ''
  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get userLogged() {
    return this.authStore.user;
  }

  get passengers() {
    return this.planningStore.passengers;
  }
  get passengersFinal() {
    return this.planningStore.passengersFinal;
  }

  get owner() {
    return this.planningStore.owner;
  }

  get ownerFinal() {
    return this.planningStore.ownerFinal;
  }

  get typeTrip() {
    return this.planningStore.searchSelected?.typeTrip || "round";
  }

  get favoritePassengers() {
    if (this.authStore.user) {
      const user = this.authStore.user;
      return user.passengersFavorite;
    }
    return [];
  }

  get allSeatings() {
    const seatings = [];
    if (this.owner) {
      seatings.push(this.owner.seating);
    }
    seatings.push(...this.passengers.map(({ seating }) => seating));
    return seatings.filter((seating) => {
      const seatingOwner = this.dataOwner.seating;
      return seating != seatingOwner;
    });
  }

  back() {
    this.planningStore.setSelectedAllSeatings(false)
    this.planningStore.setStep("EMPTY");
  }

  async nextTo() {
    const form = this.$refs["formPassenger"] as FormVuetify;
    if (!form) return;
    const valid = await form.validate();
    if (valid.valid) {
      this.planningStore.setStep("PAYMENT");
    }
    if (this.mobile) {
      await sleep(300, this.timeOut);
      customScrollTo("null", 0);
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  closeModalFavorite() {
    this.isVisibleModalFavorite = false;
    this.passengerselected = "";
  }

  setVisibleFavoritePassenger(tipo: "owner" | "seating" = "owner", key?: number) {
    this.seatingFavoriteType = tipo;
    if (key !== undefined) {
      this.seatingFavoriteSelected = key
    }
    this.isVisibleModalFavorite = true;
  }

  setPassenger() {
    const seatingFavorite = this.favoritePassengers.find((fp) => fp._id == this.passengerselected);
    if (seatingFavorite) {
      if (this.seatingFavoriteType == "owner" && this.owner) {
        this.owner.firstName = seatingFavorite.firstName;
        this.owner.lastName = seatingFavorite.lastName;
        this.owner.email = seatingFavorite.email || "";
        this.owner.phone = seatingFavorite.phone || "";
        this.owner.rut = seatingFavorite.rut;
        this.repeatEmailValue = seatingFavorite.email || "";
        this.isExistUser = true;
      }
      if (this.seatingFavoriteType == 'seating' && this.seatingFavoriteSelected !== null) {
        this.planningStore.setDataPassenger(this.seatingFavoriteSelected, {
          ...seatingFavorite
        })
      }
    }
    this.closeModalFavorite();
  }

  async format(type: "owner" | "normal", passenger?: CustomerSeating) {
    if (type === "owner" && this.owner) {
      this.owner.rut = formatRutChile(this.owner.rut);
      this.loaderUserRUT = true;
      try {
        if (this.owner.rut.length > 3) {
          const user = await this.userApi.getCustomerByRUT(this.owner.rut);
          this.owner.firstName = user.firstName;
          this.owner.lastName = user.lastName;
          this.owner.email = user.email || "";
          this.owner.phone = user.phone || "";
          this.repeatEmailValue = user.email || "";
          this.isExistUser = true;
          this.isSignUser = user.isSign;
        }
      } catch (error) {
        //getErrorCatch(error)
        console.log("Usuario no encontrado");
      }
      this.loaderUserRUT = false;
    }
    if (type === "normal" && passenger) {
      passenger.rut = formatRutChile(passenger.rut);
      passenger.isLoading = true;
      try {
        if (passenger.rut.length > 3) {
          const user = await this.userApi.getCustomerByRUT(passenger.rut);
          passenger.firstName = user.firstName;
          passenger.lastName = user.lastName;
          passenger.isLoading = false;
        }
      } catch (error) {
        console.log("Usuario no encontrado");
      }
      passenger.isLoading = false;
    }
    //this.dataForm.rut = formatRutChile(this.dataForm.rut)
  }
  validateNumber = validateNumber;
  formatReverse(type: "owner" | "normal", passenger?: CustomerSeating) {
    if (type === "owner" && this.owner) {
      this.owner.rut = this.owner.rut.replace(/[^\dKk]/g, "");
    }
    if (type === "normal" && passenger) {
      passenger.rut = passenger.rut.replace(/[^\dKk]/g, "");
    }
  }

  addRutPersonal() {
    if (this.userLogged?.rut && this.owner) {
      this.owner.rut = this.userLogged.rut;
      this.$nextTick(() => {
        this.format("owner");
      });
    }
  }

  getSeating(seating: string, key: number){
    if(this.isMassive){
      const seatingsEqual = this.tripsSelecteds.map(({ seatingSelected }) => seatingSelected !== undefined && seatingSelected.length > 0 ? seatingSelected[key + 1] : undefined)
      if (!seatingsEqual.some((value) => value !== seatingsEqual[0])) {
        const days = this.tripsSelecteds.map(({ dateExecuting }) => {
          const day = dateToDayMonth(new Date(dateExecuting).getTime())
          return `${day.day} ${day.month}`
        }).join(', ')
        return seatingsEqual[0] + `<span class="text-caption ml-1">(${days})</span>`
      }
      const seatings = this.tripsSelecteds.map(({ dateExecuting, seatingSelected }) => {
        const day = dateToDayMonth(new Date(dateExecuting).getTime())
        if (this.selectedAllSeating) {
          return `${day.day} ${day.month}`
        }
        const existsSeatings = seatingSelected && seatingSelected.length > 0 ? seatingSelected[key + 1] : 'Sin asientos'
        return `${day.day} ${day.month} <span class="text-caption">(${existsSeatings})</span>`
      }).join(', ')
      return seatings
    }
    return seating
  }
}
