
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { FormVuetify, SelectData } from '@/types/common/vuetify';
import { CustomerSeating } from '@/types/request/planning';
import { GetCalendarResponse, PlanningTripsResponse, PlanningTripsStationsResponse } from '@/types/response/planning';
import { formatRutChile, getErrorCatch, requiredField, ruleValidateRut, validateEmail, validateNumber } from '@/utils/index'
import { CreateTripRequest } from '@/types/request/trip';
import { TripApi } from '@/api/trip';
import { usePlanningStore } from '@/store/modules/planning';
import { UserApi } from '@/api/users';
import { GetUsersAutocomplete } from '@/types/response/guest';
@Options({
    name: 'AddTrip',
    components: {
        ModalCommon
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        stations: {
            type: Array,
            default: () => ([])
        },
        seatingsAvailable: {
            type: Array,
            default: () => ([])
        },
        planning: {
            type: Object,
            default: () => ({}),
            required: false
        },
        activeTrip: {
            type: Object,
            default: () => ({}),
            required: true
        },
        stationsMain: {
            type: Array,
            default: () => ([])
        },
    }
})
export default class AddTrip extends Vue {
    private tripApi = new TripApi()
    private userApi = new UserApi()
    public visible!: boolean;
    public stations!: SelectData[];
    public seatingsAvailable!: SelectData[];
    public activeTrip!: GetCalendarResponse;
    public planning !: PlanningTripsResponse;
    public stationsMain!: PlanningTripsStationsResponse[];
    public requiredField = requiredField;
    public validateEmail = validateEmail;
    ruleValidateRut = ruleValidateRut;
    public passengers: CustomerSeating[] = []
    public stationSelected = '';
    private planningStore = usePlanningStore();
    public loading = false;
    public loaderUserRUT = false;
    public activePassengers = false;
    public loaderUserExtraRUT = false;

    public otherCustomer = false;
    public loadingCustomer = false;
    public search = ''
    public customersSearch: GetUsersAutocomplete[] = []
    public customerValue = ''
    public otherOwnerFinish = false

    created(): void {
        this.$watch('search', (val: string) => {
            val && val !== this.customerValue && this.getCustomers(val)
        })
    }

    closeModal() {
        this.$emit('closeModal')
        const form = this.$refs['formOwner'] as FormVuetify
        const formPassenger = this.$refs['formAddPassenger'] as FormVuetify
        form.reset();
        form.resetValidation();
        if (formPassenger) {
            formPassenger.reset();
            formPassenger.resetValidation();
        }
        this.passengers = [];
        this.activePassengers = false;
    }
    public dataOwner: CustomerSeating = {
        seating: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        rut: ''
    };

    public dataPassenger = {
        firstName: '',
        lastName: '',
        rut: '',
        seating: ''
    }

    selectCustomer(value: GetUsersAutocomplete) {
        this.otherOwnerFinish = true;
        this.dataOwner.email = value.email
        this.customerValue = value._id
        this.dataOwner.phone = value.phone || ''
    }

    cancelOtherOwner() {
        this.otherOwnerFinish = false;
        this.dataOwner.email = '';
        this.otherCustomer = false;
        this.customerValue = ''
        this.customerValue = ''
    }

    async eventSuccess() {
        const form = this.$refs['formOwner'] as FormVuetify
        if (!form) return;
        const valid = await form.validate();
        if (valid.valid) {
            const stationSelected = this.stationsMain.find(({ _id }) => _id == this.stationSelected)
            if (!stationSelected) return;
            const data: CreateTripRequest = {
                activeTrip: this.activeTrip._id,
                hourArrival: stationSelected.hourFinish,
                hourDeparture: stationSelected.hourInit,
                seating: this.dataOwner.seating,
                totalPrice: 0,
                stationOrigin: stationSelected.routeStation.originStation,
                stationDestination: stationSelected.routeStation.finalStation,
                passengers: this.activePassengers ? this.passengers : [],
                planningTrip: this.planning._id,
                guest: {
                    ...this.dataOwner
                },
                station: this.stationSelected,
                typePayment: 'webpay',
                isOtherOwner: this.otherOwnerFinish,
                customer: this.customerValue != '' ? this.customerValue : undefined,
                typeTrip : 'justReturn',
                isMassive: false
            }
            this.loading = true
            try {
                await this.tripApi.createTripAdmin(data);
                await this.planningStore.getCalendar(this.planning._id);
                this.$emit('reloadCalendar', this.activeTrip._id)
                this.closeModal()
            } catch (error) {
                getErrorCatch(error)
            }
            this.loading = false
        }
    }
    async addPassenger() {
        const form = this.$refs['formAddPassenger'] as FormVuetify
        if (!form) return;
        const valid = await form.validate();
        if (valid.valid) {
            this.passengers.push({
                email: '',
                phone: '',
                ...this.dataPassenger
            })
            this.passengers = this.passengers.sort(this.orderSeating)
            form.reset()
            form.resetValidation()
        }
    }
    validateNumber = validateNumber;
    orderSeating(a: CustomerSeating, b: CustomerSeating) {
        const seating1 = parseInt(a.seating);
        const seating2 = parseInt(b.seating);
        return seating1 - seating2;
    }
    async format() {
        if (this.dataPassenger.rut) {
            this.dataPassenger.rut = formatRutChile(this.dataPassenger.rut)
            this.loaderUserExtraRUT = true;
            try {
                if (this.dataPassenger.rut.length > 3) {
                    const user = await this.userApi.getCustomerByRUT(this.dataPassenger.rut);
                    this.dataPassenger.firstName = user.firstName;
                    this.dataPassenger.lastName = user.lastName;
                }
            } catch (error) {
                console.log("Usuario no encontrado")
            }
            this.loaderUserExtraRUT = false;

        }
    }
    formatReverse() {
        if (this.dataPassenger.rut) {
            this.dataPassenger.rut = this.dataPassenger.rut.replace(/[^\dKk]/g, "");
        }
    }
    async formatOwner() {
        if (this.dataOwner.rut) {
            this.dataOwner.rut = formatRutChile(this.dataOwner.rut)
            this.loaderUserRUT = true;
            try {
                if (this.dataOwner.rut.length > 3) {
                    const user = await this.userApi.getCustomerByRUT(this.dataOwner.rut);
                    this.dataOwner.firstName = user.firstName;
                    this.dataOwner.lastName = user.lastName;
                    if (!this.otherOwnerFinish) {
                        this.dataOwner.email = user.email || '';
                        this.dataOwner.phone = user.phone || '';
                    }

                }
            } catch (error) {
                //getErrorCatch(error)
                console.log("Usuario no encontrado")
            }
            this.loaderUserRUT = false;
        }
    }
    formatReverseOwner() {
        if (this.dataOwner.rut) {
            this.dataOwner.rut = this.dataOwner.rut.replace(/[^\dKk]/g, "");
        }
    }

    validSeating(value: string) {
        return this.passengers.find(({ seating }) => seating === value) ? 'Asiento ya reservado por los pasajeros' : true
    }
    validSeatingOwner(value: string) {
        return value === this.dataOwner.seating ? 'Asiento ya seleccionado por el propietario' : true
    }

    async getCustomers(value: string) {
        this.loadingCustomer = true;
        try {
            const data = await this.userApi.getCustomerByName(value);
            this.customersSearch = data;
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingCustomer = false;
    }
}
