
import { usePlanningStore } from '@/store/modules/planning';
import { GetCouponNameResponse } from '@/types/response/coupon';
import { formatCurrency } from '@/utils';
import { Options, Vue } from 'vue-class-component';


@Options({
    name: "PaymentPrice",
    props: {
        couponResponse: {
            type: Object,
            default: null,
            required: false
        },
        discount: {
            type: Number,
            required: false,
            default: 0
        }
    }
})
export default class PaymentPrice extends Vue {
    public formatCurrency = formatCurrency;
    public planningStore = usePlanningStore();
    public couponResponse!: GetCouponNameResponse;
    public discount !: number;

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get searchTripBooking() {
        return this.planningStore.searchSelected
    }

    get tripSelected() {
        return this.planningStore.tripSelected
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected
    }

    get isRound() {
        if (!this.searchTripBooking) {
            return false
        }
        return this.searchTripBooking.typeTrip == 'round'
    }

    get passengers() {
        return this.planningStore.passengers;
    }

    get isEqualPrice() {
        if (!this.tripSelected || !this.tripFinalSelected) {
            return false
        }
        return this.tripSelected.price == this.tripFinalSelected.price
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get price() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            return this.tripsSelecteds[0].price
        }
        if (!this.tripSelected) return 0
        return this.tripSelected.price
    }

    get priceReturn() {
        if (!this.tripFinalSelected) return 0
        return this.tripFinalSelected.price
    }

    get total() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            const acum = this.tripsSelecteds.reduce((a, b) => a + b.price, 0)
            return acum
        }
        return 0
    }
}
