

import FormSearchBooking from '@/components/Booking/FormSearch.vue';
import { useRoutesStore } from '@/store/modules/route';
import { getDaysInRange, getErrorCatch } from '@/utils';
import { Vue, Options } from 'vue-class-component';
import BannerInfoSearch from '@/components/Booking/BannerInfoSearch.vue';
import { usePlanningStore, STEP } from '@/store/modules/planning';
import ListTripResult from '@/components/Booking/ListTripResult/ListTripResult.vue';
import Bus from '@/components/Booking/Bus.vue';
import Passengers from '@/components/Booking/Passengers.vue';
import Payment from '@/components/Booking/Payment.vue';
import DetailTrip from '@/components/Booking/DetailTrip.vue';
import { GetStatusTripResponse } from '@/types/response/trip';
import HeaderCommonBooking from '@/components/Booking/HeaderCommonBooking.vue';
import '@/scss/booking.scss'
import { useMainStore } from '@/store/modules/main';

@Options({
    name: 'BookingPage',
    components: {
        FormSearchBooking,
        BannerInfoSearch,
        ListTripResult,
        Bus,
        Passengers,
        Payment,
        HeaderCommonBooking,
        DetailTrip
    }
})
export default class BookingPage extends Vue {
    public routeStore = useRoutesStore();
    public planningStore = usePlanningStore();
    public mainStore = useMainStore();
    public messageError = '';

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    public tripResponse: GetStatusTripResponse | null = null

    get confirmQuery() {
        return this.$route.query['confirm'];
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get tripsSelected() {
        return this.planningStore.tripsSelected
    }

    get step() {
        return this.planningStore.step;
    }

    get stations() {
        return this.routeStore.stationsList
    }

    get roundType() {
        return this.planningStore.searchSelected?.typeTrip || 'round'
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }
    get isLoadingBooking() {
        return this.planningStore.isLoadingBooking;
    }
    get checkOneStep() {
        if (this.roundType == 'round') {
            return !this.tripSelected || !this.tripFinalSelected
        }
        if (this.isMassive) {
            if(this.tripsSelecteds.length == 0){
                return true;
            }
            const dates = getDaysInRange(this.searchSelected?.date as number[] || []).map(({ day }) => day).filter((day) => this.getTripsActiveDay(day));
            return dates.length !== this.tripsSelecteds.length
        }
        return !this.tripSelected
    }

    async mounted() {
        try {
            await this.routeStore.getStations();
            const successCard = this.$route.query['successCard'];
            if (successCard) {
                this.validateLocalData()
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    removeAllItems() {
        const keys = ['step', 'searchSelected', "isMassive", 'passengers', 'tripSelected', 'owner', 'tripFinalSelected', 'ownerFinal', 'passengersFinal']
        keys.forEach(key => {
            localStorage.removeItem(key);
        })
    }

    async validateLocalData() {
        const step = localStorage.getItem('step') as STEP;
        const searchSelected = localStorage.getItem('searchSelected');
        const tripSelected = localStorage.getItem('tripSelected');
        const tripFinalSelected = localStorage.getItem('tripFinalSelected');
        const passengers = localStorage.getItem('passengers');
        const passengersFinal = localStorage.getItem('passengersFinal');
        const owner = localStorage.getItem('owner');
        const ownerFinal = localStorage.getItem('ownerFinal');
        if (step && searchSelected && passengers && tripSelected && owner) {
            try {
                this.planningStore.setStep(step);
                this.planningStore.setTrip(JSON.parse(tripSelected));
                //await this.planningStore.getTripsBooking(JSON.parse(searchSelected));
                this.planningStore.setOwner(JSON.parse(owner));
                this.planningStore.setPassengers(JSON.parse(passengers));
                if (tripFinalSelected) {
                    this.planningStore.updateStageTrip('return')
                    this.planningStore.setTrip(JSON.parse(tripFinalSelected))
                }
                if (passengersFinal) {
                    this.planningStore.updateStageSeating('return');
                    this.planningStore.setPassengers(JSON.parse(passengersFinal))
                }
                if (ownerFinal) {
                    this.planningStore.updateStageSeating('return');
                    this.planningStore.setOwner(JSON.parse(ownerFinal))
                }
                this.mainStore.setNotification({
                    message: 'Viaje cargado correctamente',
                    isOpen: true,
                    color: 'green darken-3'
                })
                this.removeAllItems()
            } catch (error) {
                this.mainStore.setNotification({
                    message: 'Ocurrio un error al cargar tu viaje',
                    isOpen: true,
                    color: 'red darken-3'
                })
                this.removeAllItems()
            }
        } else {
            console.log("no cumple con validacion de sesion")
            this.removeAllItems()
        }
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }

    beforeUnmount(): void {
        this.planningStore.clearBooking()
    }


    getTripsActiveDay(day: string) {
        return this.tripsSelected.filter(({ dateExecuting }) => {
            return dateExecuting.split('T')[0].split('-')[2] == day
        }).length > 0
    }
}
