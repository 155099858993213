import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "booking",
  id: "home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailTrip = _resolveComponent("DetailTrip")!
  const _component_FormSearchBooking = _resolveComponent("FormSearchBooking")!
  const _component_ListTripResult = _resolveComponent("ListTripResult")!
  const _component_Bus = _resolveComponent("Bus")!
  const _component_Passengers = _resolveComponent("Passengers")!
  const _component_Payment = _resolveComponent("Payment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.confirmQuery)
      ? (_openBlock(), _createBlock(_component_DetailTrip, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.step === 'EMPTY')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.checkOneStep)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_FormSearchBooking),
                      _createVNode(_component_ListTripResult)
                    ], 64))
                  : (_openBlock(), _createBlock(_component_Bus, { key: 1 }))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.step === 'PASSENGERS')
                  ? (_openBlock(), _createBlock(_component_Passengers, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.step === 'PAYMENT')
                  ? (_openBlock(), _createBlock(_component_Payment, { key: 1 }))
                  : _createCommentVNode("", true)
              ], 64))
        ], 64))
  ]))
}