
import { Options, Vue } from 'vue-class-component';
import PaymentBoxRoute from '../PaymentBoxRoute.vue';
import { usePlanningStore } from '@/store/modules/planning';


@Options({
    name: "PaymentRoute",
    props: {
        isReturn: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    components: {
        PaymentBoxRoute
    }
})
export default class PaymentRoute extends Vue {
    public planningStore = usePlanningStore();
    public isReturn !: boolean;

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get origin() {
        if (this.searchTripBooking) {
            return this.isReturn ? this.searchTripBooking.destination : this.searchTripBooking.origin
        }
        return ''
    }

    get destination() {
        if (this.searchTripBooking) {
            return this.isReturn ? this.searchTripBooking.origin : this.searchTripBooking.destination
        }
        return ''
    }

    get hourInit() {
        if (this.planningStore.buyMassive && this.planningStore.tripsSelecteds.length > 0) {
            return this.planningStore.tripsSelecteds[0].hourInit
        }
        if (this.isReturn) {
            if (this.planningStore.tripFinalSelected) {
                return this.planningStore.tripFinalSelected.hourInit
            }
            return '-'
        }
        if (this.planningStore.tripSelected) {
            return this.planningStore.tripSelected.hourInit
        }
        return ''
    }

    get hourFinish() {
        if (this.planningStore.buyMassive && this.planningStore.tripsSelecteds.length > 0) {
            return this.planningStore.tripsSelecteds[0].hourFinish
        }
        if (this.isReturn) {
            if (this.planningStore.tripFinalSelected) {
                return this.planningStore.tripFinalSelected.hourFinish
            }
            return '-'
        }
        if (this.planningStore.tripSelected) {
            return this.planningStore.tripSelected.hourFinish
        }
        return ''
    }
}
