import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "text-center text-secondary title-typetrip",
  id: "type-trip-title"
}
const _hoisted_3 = { class: "text-center subtitle-typetrip" }
const _hoisted_4 = { class: "text-center subtitle-typetrip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_ctx.searchSelected)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.stageTrip == "outbound" ? "Viaje de ida" : "Viaje de vuelta"), 1),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(" (" + _toDisplayString(_ctx.getRouteById(_ctx.searchSelected.origin as string)) + " ", 1),
          _createVNode(_component_v_icon, {
            size: "small",
            color: "primary",
            icon: _ctx.stageTrip == 'outbound'
                ? 'mdi-arrow-right-bold'
                : 'mdi-arrow-left-bold'
                
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.getRouteById(_ctx.searchSelected.destination as string)) + ") ", 1)
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getDate(
                    _ctx.stageTrip === "outbound"
                        ? (_ctx.searchSelected.date as number)
                        : (_ctx.searchSelected.dateFinal as number),
                    "onlyDate",
                    "NUMBER"
                )), 1)
      ]))
    : _createCommentVNode("", true)
}