
import { usePlanningStore } from '@/store/modules/planning';
import { Options, Vue } from 'vue-class-component';
import locationPrimary from '@/assets/images/icons/location-primary.svg'
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { useRoutesStore } from '@/store/modules/route';
import locationSecondary from '@/assets/images/icons/location-secondary.svg'

import Line from '@/assets/images/icons/linea.svg'

@Options({
    name: 'RouteBus',
})
export default class RouteBus extends Vue {
    public planningStore = usePlanningStore();
    public routeStore = useRoutesStore();
    public locationPrimary = locationPrimary;
    public Line = Line;
    public locationSecondary = locationSecondary;

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get stageSeating() {
        return this.planningStore.stageSeatings
    }

    get titleDetail() {
        return this.stageSeating === 'return' ? 'Viaje de vuelta' : 'Viaje de ida'
    }

    get dateRound() {
        if (!this.searchTripBooking) {
            return ''
        }
        return getDate(this.stageSeating == 'outbound' ? this.searchTripBooking.date as number : this.searchTripBooking.dateFinal as number, 'onlyDate', 'NUMBER')
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected;
    }

    get convertHourInit() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            return convertTo12HourFormat(this.tripsSelecteds[0].hourInit)
        }
        if (!this.tripSelected) return ''
        return convertTo12HourFormat(this.stageSeating == 'outbound' ? this.tripSelected.hourInit : this.tripFinalSelected?.hourInit)
    }

    get convertHourFinish() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            return convertTo12HourFormat(this.tripsSelecteds[0].hourFinish)
        }
        if (!this.tripSelected) return ''
        return convertTo12HourFormat(this.stageSeating == 'outbound' ? this.tripSelected.hourFinish : this.tripFinalSelected?.hourFinish)
    }

    get durationTrip() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            return this.tripsSelecteds[0].durationTrip
        }
        if (!this.tripSelected) return ''
        return this.tripSelected.durationTrip
    }

    get stations() {
        return this.routeStore.stationsList
    }

    get allDaysSelected() {
        return this.planningStore.selectedAllDays
    }

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    get days() {
        return this.planningStore.rangeDatesMassive
    }

    get tripsSearch() {
        return this.planningStore.tripsSelected;
    }

    get titleAllDays() {
        if (this.isMassive) {
            const daysFilter = this.days.filter(({ disabled }) => !disabled);
            if (daysFilter.length == 0) {
                return ' ERROR ';
            }
            let title = "";
            if (daysFilter.length == 1) {
                title = `<b>Dia seleccionado</b>: `
            } else {
                title = "<b>Dias seleccionados</b>: "
            }
            return `${title} ${this.days.filter(({ disabled }) => !disabled).map(({ day, month }) => `${month} ${day}`).join(", ")} `

        }
        return ''
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }
}
