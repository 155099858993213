
import { usePlanningStore } from '@/store/modules/planning';
import { useRoutesStore } from '@/store/modules/route';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: "DescriptionMassive"
})
export default class DescriptionMassive extends Vue {
    public planningStore = usePlanningStore();
    private routeStore = useRoutesStore();

    get stageTrip() {
        return this.planningStore.stageTrip;
    }
    get isMassive() {
        return this.planningStore.buyMassive
    }

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    get tripsSearch() {
        return this.planningStore.tripsSelected;
    }

    get days() {
        return this.planningStore.rangeDatesMassive;
    }

    get allDaysSelected() {
        return this.planningStore.selectedAllDays
    }

    get daySelected() {
        return this.planningStore.daySelectedMassive
    }

    get tripsSelecteds(){
        return this.planningStore.tripsSelecteds
    }

    get titleAllDays() {
        if (this.allDaysSelected) {
            try {
                const dayOne = this.days[0];
                const dayLast = this.days[this.days.length - 1];
                return `Desde <b>${dayOne.month.charAt(0).toUpperCase()}${dayOne.month.slice(1)} ${dayOne.day}</b> hasta <b>${dayLast.month.charAt(0).toUpperCase()}${dayLast.month.slice(1)} ${dayLast.day}</b> `
            } catch (error) {
                return ''
            }
        }
        return ''
    }

    selectDay(day: string, disabled: boolean) {
        if (!this.allDaysSelected && !disabled) {
            this.planningStore.setDaySelectedMassive(day);
        }
    }

    setAllDays(value: boolean) {
        this.planningStore.setSelectedAllDays(value)
    }

    getDaySelected(day: string){
        const trip =  this.tripsSelecteds.find(({dateExecuting}) => {
            return dateExecuting.split("T")[0].split("-")[2] == day
        })
        if(trip){
            return trip.hourInit
        }
        return ''
    }
}
