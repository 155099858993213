

import { usePlanningStore } from '@/store/modules/planning';
import { Vue, Options } from 'vue-class-component';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import DetailBooking from './Bus/DetailBooking.vue';

@Options({
    name: 'Bus',
    components: {
        HeaderCommonBooking,
        DetailBooking
    }
})
export default class Bus extends Vue {
    public planningStore = usePlanningStore();

    back() {
        this.planningStore.resetSearchTrip()
        this.planningStore.updateStageTrip('outbound');
        this.planningStore.removeTripSelected()
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get tripsSelected() {
        return this.planningStore.tripsSelecteds
    }

    get validateBus() {
        if (this.isMassive) {
            return this.tripsSelected.length > 0 && this.searchTripBooking
        }
        return this.tripSelected && this.searchTripBooking
    }

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }
}
