
import { getDate } from '@/filters/formatDate';
import { usePlanningStore } from '@/store/modules/planning';
import { Options, Vue } from 'vue-class-component';
import { dateToDayMonth } from '@/utils/index'


@Options({
    name: "PaymentSchedule",
    props: {
        isReturn: {
            type: Boolean,
            required: false,
            default: false
        }
    }
})
export default class PaymentSchedule extends Vue {
    public planningStore = usePlanningStore();
    public getDate = getDate;
    public isReturn !: boolean;

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }
    get tripsSelecteds() {
        return this.planningStore.tripsSelecteds
    }

    get isMassive() {
        return this.planningStore.buyMassive
    }

    get titleDates() {
        if (this.isMassive) {
            if (this.tripsSelecteds.length > 0) {
                return 'Fechas de viajes'
            }
            return ''
        }
        return 'Fecha de viaje'
    }

    get dateExecuting() {
        if (!this.searchTripBooking) return '';
        if (this.isMassive) {
            const tripsSelecteds = this.tripsSelecteds.map(({ dateExecuting, hourInit }) => ({ ...dateToDayMonth(new Date(dateExecuting).getTime()), hourInit }))
                .map(({ day, month,hourInit }) => `<p>${month} ${day} <span class="text-caption text-grey-darken-2">(${hourInit})</span></p>`);
            return tripsSelecteds.join("")
        }
        if (this.isReturn) {
            return getDate(
                this.searchTripBooking.dateFinal as number,
                "onlyDate",
                "NUMBER",
                false
            )
        }
        return getDate(
            this.searchTripBooking.date as number,
            "onlyDate",
            "NUMBER",
            false
        )
    }

    get durationTrip() {
        if (this.isMassive && this.tripsSelecteds.length > 0) {
            return this.planningStore.tripsSelecteds[0].durationTrip
        }
        if (!this.planningStore.tripSelected) return ''
        return this.planningStore.tripSelected.durationTrip
    }
}
