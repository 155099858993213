
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import { usePlanningStore } from "@/store/modules/planning";
import { useRoutesStore } from "@/store/modules/route";
import { convertTo12HourFormat, getDate } from "@/filters/formatDate";
import { formatCurrency, getErrorCatch, requiredField } from "@/utils";
import { TripApi } from "@/api/trip";
import { CreateMassiveTripsDTO, CreateTripRequest } from "@/types/request/trip";
import { GetCouponNameResponse } from "@/types/response/coupon";
import { useMainStore } from "@/store/modules/main";
import { useAuthStore } from "@/store/modules/auth";
import TextLoaderButton from "../common/LoaderButtons.vue";
import PaymentBoxRoute from "./PaymentBoxRoute.vue";
import PaymentRoute from "./Payment/PaymentRoute.vue";
import PaymentSchedule from "./Payment/PaymentSchedule.vue";
import PaymentPrice from "./Payment/PaymentPrice.vue";
import PaymentCoupon from "./Payment/PaymentCoupon.vue";
import PaymentMethods from "./Payment/PaymentMethods.vue";
import { CreateTripFreeResponse, CreateTripResponse, CreateMassiveFreeResponse } from "@/types/response/trip";

@Options({
  name: "Payment",
  components: {
    HeaderCommonBooking,
    TextLoaderButton,
    PaymentBoxRoute,
    PaymentRoute,
    PaymentSchedule,
    PaymentPrice,
    PaymentCoupon,
    PaymentMethods
  },
})
export default class Payment extends Vue {
  public routeStore = useRoutesStore();
  private authStore = useAuthStore();
  public planningStore = usePlanningStore();
  public mainStore = useMainStore();
  private tripApi = new TripApi();
  public convertTo12HourFormat = convertTo12HourFormat;
  public formatCurrency = formatCurrency;
  public requiredField = requiredField;
  public getDate = getDate;
  public policy = false;
  public loadingTrip = false;
  public isCoupon = false;
  public couponName = "";
  public couponResponse: GetCouponNameResponse | null = null;
  public discount = 0;

  get searchTripBooking() {
    return this.planningStore.searchSelected;
  }

  get isMassive() {
    return this.planningStore.buyMassive
  }

  get validateVisible() {
    if (this.isMassive) {
      return this.planningStore.tripsSelecteds.length > 0
    }
    if (this.searchTripBooking) {
      return this.tripSelected
    }
    return false
  }

  get passengers() {
    return this.planningStore.passengers;
  }

  get passengersFinal() {
    return this.planningStore.passengersFinal;
  }

  get stations() {
    return this.routeStore.stationsList;
  }

  get tripSelected() {
    return this.planningStore.tripSelected;
  }

  get tripFinalSelected() {
    return this.planningStore.tripFinalSelected;
  }

  get tripsSelecteds() {
    return this.planningStore.tripsSelecteds
  }

  get total() {
    if (this.isMassive && this.tripsSelecteds.length > 0) {
      const acum = this.tripsSelecteds.reduce((a, b) => a + b.price, 0)
      return acum * (1 + this.passengers.length);
    }
    let price = this.tripSelected?.price || 0;
    if (this.searchTripBooking?.typeTrip === "round") {
      price += this.tripFinalSelected?.price || 0;
    }
    return price * (1 + this.passengers.length);
  }

  get owner() {
    return this.planningStore.owner;
  }

  get ownerFinal() {
    return this.planningStore.ownerFinal;
  }

  getStationSelected(id?: string) {
    return this.stations.find(({ _id }) => _id === id)?.name || "";
  }

  formatNumber(num: number) {
    if (num < 10) {
      return "0" + num;
    }
    return num + "";
  }

  back() {
    this.planningStore.setStep("PASSENGERS");
  }
  async nextTo(select: number) {
    let data: CreateTripRequest | CreateMassiveTripsDTO | null = null;
    if (this.isMassive && this.tripsSelecteds.length > 0 && this.owner && this.searchTripBooking) {
      const createMassive: CreateMassiveTripsDTO = {
        trips : [],
        paymentEntry : {
          guest: this.owner,
          typePayment: select === 1 ? "webpay" : "oneclick",
          coupon: this.couponResponse ? this.couponName : undefined,
        }
      }
      for (let index = 0; index < this.tripsSelecteds.length; index++) {
        const trip = this.tripsSelecteds[index];
        const seatingOwner = trip.seatingSelected || []
        createMassive.trips.push({
          activeTrip: trip.activeTrip,
          passengers: this.passengers.map((passenger, key) => {
            return {
              ...passenger,
              seating: seatingOwner[key + 1]
            }
          }),
          planningTrip: trip.planingTrip,
          seating: seatingOwner[0],
          station: trip._id,
          stationOrigin: this.getStationSelected(this.searchTripBooking.origin),
          stationDestination: this.getStationSelected(
            this.searchTripBooking.destination
          ),
        })
      }
      data = createMassive;
    }
    if (this.tripSelected && this.owner && this.searchTripBooking) {
      data = {
        activeTrip: this.tripSelected.activeTrip,
        hourArrival: this.tripSelected.hourFinish,
        hourDeparture: this.tripSelected.hourInit,
        seating: this.owner.seating,
        totalPrice: this.total,
        stationOrigin: this.getStationSelected(this.searchTripBooking.origin),
        stationDestination: this.getStationSelected(
          this.searchTripBooking.destination
        ),
        passengers: this.passengers,
        planningTrip: this.tripSelected.planingTrip,
        guest: {
          ...this.owner,
        },
        station: this.tripSelected._id,
        coupon: this.couponResponse ? this.couponName : undefined,
        typePayment: select === 1 ? "webpay" : "oneclick",
        typeTrip: this.searchTripBooking.typeTrip,
        isMassive: false
      };
      if (data.typeTrip === "round") {
        if (
          this.tripFinalSelected &&
          this.ownerFinal &&
          this.passengersFinal
        ) {
          data.returnTrip = {
            activeTrip: this.tripFinalSelected.activeTrip,
            hourArrival: this.tripFinalSelected.hourFinish,
            hourDeparture: this.tripFinalSelected.hourInit,
            planningTrip: this.tripFinalSelected.planingTrip,
            seatingOwner: this.ownerFinal.seating,
            station: this.tripFinalSelected._id,
            seatings: this.passengersFinal.map(({ seating }) => seating),
            totalPrice:
              this.tripFinalSelected.price * this.passengersFinal.length,
          };
        } else {
          this.mainStore.setNotification({
            isOpen: true,
            message: "Ocurrio un problema con la información de retorno",
            color: "red-darken-3",
            timeout: 3000,
          });
          console.error("dataReturn not exist");
          return;
        }
      }
    }
    if (data == null) {
      return;
    }
    this.loadingTrip = true;
    try {
      let responseTrip: CreateTripResponse | CreateTripFreeResponse | CreateMassiveFreeResponse | undefined = undefined;
      if (this.isMassive) {
        responseTrip = await this.tripApi.createTripMassive(data as any);
      } else {
        responseTrip = await this.tripApi.createTrip(
          data as CreateTripRequest,
          this.authStore.isLogged
        );
      }
      if ((responseTrip as CreateTripFreeResponse).code) {
        window.location.href =
          "/booking?confirm=" +
          (responseTrip as CreateTripFreeResponse).code;
      } else if((responseTrip as unknown as CreateMassiveFreeResponse).massiveId){
        window.location.href = "/booking?confirm=" + (responseTrip as unknown as CreateMassiveFreeResponse).massiveId + "&isMassive=true";
      } else {
        const { token, url } = responseTrip as CreateTripResponse;
        window.location.href = `${url}?token=${token}`;
      }
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingTrip = false;
  }
  searchCoupon({ couponResponse, discount, name }: { couponResponse: GetCouponNameResponse | null, discount: number, name: string }) {
    this.couponResponse = couponResponse;
    this.discount = discount
    this.couponName = name
  }
}
